import React, { useEffect, useState } from 'react';
import { verifyToken } from '../../api/subscribe';
import { toast } from 'react-toastify';
import Spinner from '../../svg/spinner';
import { useNavigate } from 'react-router-dom';

const RedirectingPage = () => {
  const [token, setToken] = useState<string | null>(null);
  const [spinner, setSpinner] = useState<boolean>(true);
  const navigate = useNavigate();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenValue = params.get('token');
    if (!tokenValue) {
      toast.error("Please try again with correct URL");
      setTimeout(() => {
        navigate('/')
      }, 3000)
    }
    setToken(tokenValue);
  }, []);

  useEffect(() => {
    const fetchToken = async () => {
      if (token) {
        const res = await verifyToken(token);
        if (res.success) {
          setSpinner(false)
          toast.success("Email confirmation successful!");
          setTimeout(() => {
            navigate('/')
          }, 3000)
        } else {
          toast.error(res.error);
          setTimeout(() => {
            navigate('/')
          }, 3000)
        }
      }
    };
    fetchToken();
  }, [token]);

  return (
    <>
      <div className={`w-full h-screen flex flex-col relative`} style={{ backgroundImage: "url('assets/img/redirectingPage.png')", backgroundSize: 'cover' }}>
        <div
          className={`${spinner ? "block" : "hidden"
            } fixed z-[200] inset-0 h-full flex justify-center items-center bg-[black] bg-opacity-70`}
        >
          <Spinner />
        </div>
        <div className='w-[66%] left-[17%] absolute top-[100px]'>
          <img src='assets/img/thunder-logo.png' className='opacity-80 md:-mb-5 lg:-mb-10 2xl:-mb-20' />
          <p className='md:text-[36px] lg:text-[48px] 2xl:text-[64px] text-center'>Email confirmation successful!</p>
          <p className='md:text-[18px] lg:text-[24px] 2xl:text-[32px] text-center'>Redirecting to site...</p>
        </div>
      </div>
    </>
  );
};

export default RedirectingPage;