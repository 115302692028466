export const subscribe = async (email: string) => {
  const url = `https://backend.iplaymore.com/api/auth/subscribe`;
  const strData = JSON.stringify({
    email: email
  });
  const res = await (
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: strData,
    })
  ).json();

  return res;
};

export const verifyToken = async (token: string) => {
  const url = `https://backend.iplaymore.com/api/auth/verifyToken`;
  const strData = JSON.stringify({
    token: token
  });
  const res = await (
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: strData,
    })
  ).json();

  return res;
};