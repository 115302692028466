import { useInView } from 'react-intersection-observer';
import IntroContent from '../../components/landing/introContent';

const SecondSection = () => {
    const { ref, inView } = useInView({ threshold: 0.1 });

    return (
        <div className='flex flex-col w-full'>
            <div className={`hidden absolute top-[1000px] left-0 w-full h-[calc(200px+30vw)] lg:flex flex-col items-center z-[200] transition-opacity duration-1000`} style={{ opacity: inView ? 0 : 1, }} />
            <div style={{
                backgroundImage: 'url(assets/img/landing/second-bg.png)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                transition: 'opacity 3s ease-in-out'
            }} className='relative hidden lg:flex flex-col items-center justify-between w-full h-[214px] md:h-auto'>
                <div ref={ref} className='absolute top-[100px] h-[500px] w-full'></div>
                <div style={{ backgroundImage: 'url(assets/img/landing/section-line.png)', backgroundSize: 'cover', backgroundPosition: 'center' }} className="z-[100] w-full h-[26px] flex flex-col items-center">
                    <img src='assets/img/landing/make-your-choice.png' className='w-[165px] md:w-[282px] h-[12px] md:h-[22px] mt-4 z-[50]' />
                    <img src='assets/img/landing/title-line.png' className='w-[604px] h-[10px] mt-2 z-[50]' />
                </div>
                <IntroContent />
            </div>
            <div className='relative lg:hidden flex flex-col items-center md:justify-between w-full h-fit'>
                <div style={{ backgroundImage: 'url(assets/img/landing/section-line.png)', backgroundSize: 'cover', backgroundPosition: 'center' }} className="z-[100] w-full h-[26px] flex flex-col items-center">
                    <img src='assets/img/landing/make-your-choice.png' className='w-[165px] md:w-[282px] h-[12px] md:h-[22px] mt-4 z-[50]' />
                    <img src='assets/img/landing/title-line.png' className='w-[604px] h-[10px] mt-2 z-[50]' />
                </div>
                <IntroContent />
                <img src='assets/img/landing/section-line-mb.png' className='w-full h-[14px] mt-4 z-[50]' />
                <img src='assets/img/landing/second-bg.png' className='w-full mt-2 z-[50]' />
            </div>
        </div>
    )
}
export default SecondSection;