import { Link } from "react-router-dom";
import { MenuItem } from "../header/menuItem";
import { useRef, useState } from "react";
import { subscribe } from "../../api/subscribe";
import { toast } from "react-toastify";

const Footer = ({ type }: { type: string }) => {

  const inputRef = useRef<HTMLDivElement>(null);
  const handleSubscribe = async () => {
    const inputText = inputRef.current?.textContent || "";
    const res = await subscribe(inputText);
    if (res.success) {
      toast.success("Confirmation email sent");
    } else {
      toast.success(res.error);
    }
  };
  return (
    <div style={{ backgroundImage: `url(assets/img/footer-bg${type === "sm" ? "-sm" : ""}.png)`, backgroundSize: 'cover', backgroundPosition: 'top' }} className={` pointer-events-auto z-[500] flex flex-col w-full ${type === "sm" ? "h-[134px]" : "h-[540px]"} -mb-2 bg-cover items-center py-4 px-4 lg:px-10 justify-between -mt-1`}>
      <div className={`${type === "sm" && "hidden"} flex flex-col items-center pt-12`}>
        <img src='assets/img/landing/sub-title.png' className='w-full mt-1 md:w-[80%] lg:w-[920px] lg:h-[68px] z-[50]' />
        <p className='text-[23px] text-center lg:text-[21px] mb-4 h-[128px] md:h-[40px] lg:h-fit'>Receive updates and calls to action as releases of our game become exclusively available online.</p>
        <div className="relative">
          <div
            ref={inputRef}
            style={{
              backgroundImage: 'url(assets/img/landing/text-box.png)',
              backgroundSize: 'cover'
            }}
            contentEditable={true} suppressContentEditableWarning={true}
            className='leading-[48px] md:leading-[78px] focus:outline-none text-center text-[14px] md:text-[24px] hover:text-secondary-hover font-[400] flex items-center justify-center w-[350px] mt-1 md:w-[556px] h-[48px] md:h-[78px] md:mt-4 z-[50]'
            onPaste={(e) => {
              e.preventDefault();
              const text = e.clipboardData.getData('text');
              document.execCommand('insertText', false, text);
            }}
          >
            {""}
          </div>
        </div>
        <div
          style={{
            backgroundImage: 'url(assets/img/landing/sub-btn.png)',
            backgroundSize: 'cover'
          }}
          onClick={handleSubscribe}
          className='cursor-pointer text-[14px] md:text-[24px] hover:text-secondary-hover font-[400] flex items-center justify-center w-[130px] h-[57px] mt-5 md:w-[178px] md:h-[78px] md:mt-3 z-[50] animate-moveUpDown'
        >Subscribe
        </div>
      </div>
      <div className='flex flex-col w-full md:w-[800px] items-center justify-between h-[100px]'>
        <div className="flex items-center gap-3 mb-1">
          <Link to="/iplaymore"><img src="assets/img/logo-sm.png" className="w-[90px] md:w-[120px] md:h-[44px]" /></Link>
          <Link to="/"><img src="assets/img/logo-left-sm.png" className="w-[140px] md:w-[216px] md:h-[22px]" /></Link>
        </div>
        <div className="flex flex-wrap justify-center items-center gap-[6px] md:gap-4 text-[8px] md:text-[11px] mt-[2px] md:mt-1">
          <MenuItem to="/">Home</MenuItem>
          <MenuItem to="/">Marketokace</MenuItem>
          <MenuItem to="/the-game">The Game</MenuItem>
          <MenuItem to="/novel">Novel</MenuItem>
          <MenuItem to="/art-music">Art & Music</MenuItem>
          <MenuItem to="/prologue">Prologue</MenuItem>
          <MenuItem to="/twich-tool">Twitch Tool</MenuItem>
          <MenuItem to="/iplaymore">Who we are</MenuItem>
          <MenuItem to="/testimonials">Testimonials</MenuItem>
          <MenuItem to="/update-logs">Update Logs</MenuItem>
        </div>
        <p className="text-[8px] md:text-[11px] mt-[2px] md:mt-1">
          © iPlayMore - ALL RIGHT RESERVED
        </p>
        <p className="text-[8px] md:text-[11px] mt-[2px] md:mt-1">
          Proprietary Copyright  Intellectual Property of IMU Studios
        </p>
      </div>
    </div>
  );
};

export default Footer;