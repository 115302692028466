import { useState } from "react";

const FirstSection = () => {
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);

  const handleVideoClick = (url: string) => {
    setSelectedVideo(url);
  };
  const twichToolUrl = "https://www.youtube.com/watch?v=R6tAwjXAU6Y";
  const closeModal = () => {
    setSelectedVideo(null);
  };

  return (
    <div
      style={{
        backgroundImage: "url(assets/img/landing/landing-bg.png)",
        backgroundSize: "cover",
      }}
      className="flex flex-col items-center w-full h-[350px] md:h-[600px] lg:h-[951px] relative"
    >
      {selectedVideo && (
        <div
          className="z-[2000] w-screen top-0 left-0 h-screen fixed flex justify-center items-center bg-[#0000005a]"
          onClick={closeModal}
        >
          <div className="flex items-center justify-center w-[768px] h-[500px]">
            <iframe
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${
                selectedVideo.split("v=")[1]
              }`}
              title="Selected Video"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
      <img
        src="assets/img/landing/landing-bg-2.png"
        className="w-full hidden md:absolute bg-[#0000009f] lg:h-[960px]"
      />
      <div className="w-full hidden md:flex flex-col justify-center items-center mt-8">
        <img
          src="assets/img/landing/discover-to-your-hero.png"
          className="w-[80%] md:h-auto lg:h-12 z-[50]"
        />
        <img
          src="assets/img/landing/title-line.png"
          className="w-[90%] lg:w-[904px] md:h-auto lg:h-[15px] mt-2 lg:mt-6 z-[50]"
        />
      </div>
      <div className="w-full absolute flex top-4 md:hidden flex-col justify-center items-center z-[100]">
        <img
          src="assets/img/landing/discover-to-your-hero.png"
          className="w-[80%] lg:w-[1490px] lg:h-[11px] md:h-12 z-[50]"
        />
        <img
          src="assets/img/landing/title-line.png"
          className="w-[90%] md:w-[904px] lg:h-[15px] mt-2 md:mt-6 z-[50]"
        />
      </div>
      <div className="w-full h-full md:w-full max-w-[1357px] md:h-[500px] lg:h-[766px] md:mt-6 z-[50] relative">
        <video className="w-full h-full object-cover" autoPlay loop muted>
          <source src="assets/img/landing/video-frame.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div
          style={{
            backgroundImage: "url(assets/img/landing/button-bg.png)",
            backgroundSize: "cover",
          }}
          className="cursor-pointer text-[14px] lg:text-[24px] hover:text-secondary-hover font-[400] flex items-center justify-center w-[292px] lg:w-[556px] h-[41px] lg:h-[78px] z-[50] absolute top-2/3 left-[calc(50%-146px)] lg:left-[calc(50%-278px)] animate-moveUpDown"
          onClick={() => handleVideoClick(twichToolUrl)}
        >
          Click here to view our teaser!
        </div>
      </div>
    </div>
  );
};
export default FirstSection;
