import { useState, useRef, useEffect } from "react";
import HTMLFlipBook from "react-pageflip";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import React from "react";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";
import samplePDF from "../../assets/novel.pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

const Book = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/legacy/build/pdf.worker.min.mjs",
    import.meta.url
  ).toString();

  const [zoomState, setZoom] = useState({
    zoom: 1,
    allowZoom: true,
    allowPan: true,
  });
  const book = useRef();
  const [numPages, setNumPages] = useState(0);

  const onPage = (e) => {
    document.getElementById("page-input").value = e.data;
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const PageComponent = React.forwardRef(({ pageNumber }, ref) => {
    return (
      <div ref={ref}>
        <ReactPdfPage
          scale={1}
          width={props.isMobile ? 360 : 600}
          height={props.isMobile ? 500 : 762}
          pageNumber={pageNumber}
        />
      </div>
    );
  });

  return (
    <>
      <div className="w-full flex flex-col items-center">
        <div className="flex w-fit absolute right-0 -top-1 items-center gap-1 bg-[#0F0F0F] h-[63px] px-[14px] rounded-bl-[18px] mt-2 mb-4">
          {zoomState.allowZoom ? (
            <img
              src="assets/img/novel/locked.png"
              onClick={() =>
                setZoom({
                  zoom: 1,
                  allowZoom: false,
                  allowPan: false,
                })
              }
              alt="Un-locked"
              className="w-10 h-10 mx-1 cursor-pointer"
            />
          ) : (
            <img
              src="assets/img/novel/un-locked.png"
              onClick={() =>
                setZoom({
                  zoom: 1,
                  allowZoom: true,
                  allowPan: true,
                })
              }
              alt="Locked"
              className="w-10 h-10 mx-1 cursor-pointer"
            />
          )}
        </div>
        <div className="flex w-fit items-center gap-1 bg-[#0F0F0F] h-[63px] px-[14px] rounded-[18px] mt-2 mb-4">
          <button
            className="w-[70px] h-[40px] text-[18px] rounded-[18px] bg-[#343940]"
            onClick={() => book.current.pageFlip().flipPrev()}
          >
            Prev
          </button>
          <div className="rounded-[18px] bg-[#343940] w-[89px] h-[40px] flex justify-center items-center gap-2">
            <input
              id="page-input"
              type="text"
              defaultValue={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (!isNaN(e.target.value)) {
                    book.current.pageFlip().flip(Number(e.target.value));
                  }
                }
              }}
              className="w-[24px] h-[20px] text-[18px] text-right pr-1 bg-[#D9D9D9] text-[black]"
            />{" "}
            of
            <span> {numPages + 1} </span>
          </div>
          <button
            className="w-[70px] h-[40px] text-[18px] rounded-[18px] bg-[#343940]"
            onClick={() => book.current.pageFlip().flipNext()}
          >
            Next
          </button>
        </div>
      </div>
      <div
        className={`w-full h-full flex justify-center items-center pb-[160px] md:pb-0`}
      >
        <Document file={samplePDF} onLoadSuccess={onDocumentLoadSuccess}>
          <TransformWrapper
            initialScale={1}
            initialPositionX={0}
            initialPositionY={0}
            disabled={zoomState.allowPan}
            className="w-full h-full"
          >
            <TransformComponent className="w-full h-full">
              <HTMLFlipBook
                width={props.isMobile ? 360 : 600}
                height={props.isMobile ? 500 : 762}
                size="stretch"
                minWidth={props.isMobile ? 360 : 600}
                minHeight={props.isMobile ? 500 : 762}
                maxShadowOpacity={0.5}
                showCover={true}
                usePortrait={props.isMobile ? true : false}
                mobileScrollSupport={false}
                onFlip={onPage}
                ref={book}
              >
                <div
                  className={`w-fit h-fit flex justify-center items-center`}
                  key="page_first"
                >
                  <img src="assets/img/novel/first-page.png" alt="First Page" />
                </div>
                {Array.from(new Array(numPages), (el, index) => (
                  <div
                    className={`w-fit h-fit bg-[#D9D9D9] shadow-2xl flex justify-center items-center`}
                    key={`page_${index + 1}`}
                  >
                    <PageComponent
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                    />
                  </div>
                ))}
                <div className={`w-fit`} key="page_last">
                  <img src="assets/img/novel/last-page.png" alt="Last Page" />
                </div>
              </HTMLFlipBook>
            </TransformComponent>
          </TransformWrapper>
        </Document>
      </div>
    </>
  );
};

export default Book;
