import { useEffect, useState } from 'react';
import Header from '../../components/header';
import './index.css'
import FirstSection from './firstSection';
import SecondSection from './secondSection';
import ThirdSection from './thirdSection';
import FourthSection from './fourthSection';

const LandingPage = () => {

  return (
    <div className={`w-full h-full flex flex-col bg-[#000000]`}>
      <Header />
      <div className='flex flex-col w-full items-center'>
        <FirstSection />
        <SecondSection />
        <ThirdSection />
        <FourthSection />
      </div>
    </div>
  );
};

export default LandingPage;