import React, { useEffect, useState } from 'react';

const InitPage = () => {
  const [opacity, setOpacity] = useState(true);
  const [visible, setVisible] = useState(true);
  const [remove, setRemove] = useState(true);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpacity(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setRemove(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {remove &&
        <>
          <div className={`fixed top-0 left-0 w-full h-full flex flex-col items-center z-[1000] bg-[black] transition-opacity duration-1000 ${visible ? 'opacity-100' : 'opacity-0'}`} />
          <div className={`z-[2100] fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[black] transition-opacity duration-500 ${opacity ? 'opacity-100' : 'opacity-0'}`}>
            <img src={isMobile ? "assets/img/initialPage-mb.png" : "assets/img/initialPage.png"} alt="FullScreen" className="object-cover w-full h-full" />
          </div>
        </>
      }
    </>
  );
};

export default InitPage;