import Header from '../../components/header';
import Footer from '../../components/footer';
import { useEffect, useRef, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

const musicUrl = "https://www.youtube.com/watch?v=9uQSypZ3Mz0"
const animations = [
  'https://www.youtube.com/watch?v=qVpTvr4MoxI',
  'https://www.youtube.com/watch?v=IOLyf2ToLqw',
  'https://www.youtube.com/watch?v=EPKgKFkt-xo',
  'https://www.youtube.com/watch?v=0xZRm-WIlbs',
  'https://www.youtube.com/watch?v=m6KfEMVZVec',
  'https://www.youtube.com/watch?v=DilyZCnmlW4',
];
const intro = [
  'assets/img/art-music/introduction/2.png',
  'assets/img/art-music/introduction/1.png',
  'assets/img/art-music/introduction/3.png',
  'assets/img/art-music/introduction/4.png',
];
const concepts = [
  'assets/img/art-music/concept/1.png',
  'assets/img/art-music/concept/2.png',
  'assets/img/art-music/concept/3.png',
  'assets/img/art-music/concept/4.png',
  'assets/img/art-music/concept/5.png',
  'assets/img/art-music/concept/6.png',
];
const threeD = [
  'assets/img/art-music/3d/1.png',
  'assets/img/art-music/3d/2.png',
  'assets/img/art-music/3d/3.png',
  'assets/img/art-music/3d/4.png',
  'assets/img/art-music/3d/5.png',
  'assets/img/art-music/3d/6.png',
];

const delay = 6000;

const ArtPage = () => {
  const [indexIntro, setIndexIntro] = useState(1);
  const [indexConcept, setIndexConcept] = useState(1);
  const [indexThreeD, setIndexThreeD] = useState(1);
  const [indexAnimation, setIndexAnimation] = useState(1);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<{ pack: number; index: number } | null>(null);

  const getNextIndex = (currentIndex: number, pack: number, direction: 'next' | 'prev') => {
    const length = pack === 0 ? intro.length : pack === 1 ? concepts.length : threeD.length;
    return direction === 'next'
      ? (currentIndex + 1) % length
      : (currentIndex - 1 + length) % length;
  };

  const handleImageClick = (pack: number, index: number) => {
    setSelectedImage({ pack, index });
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  const handleVideoClick = (url: string) => {
    setSelectedVideo(url);
  };

  const closeModal = () => {
    setSelectedVideo(null);
  };

  return (
    <div className={`relative w-full h-fit flex flex-col bg-[#00000076]`}>
      <Header />
      <div style={{ backgroundImage: 'url(assets/img/privacy.png)', backgroundRepeat: 'repeat', backgroundSize: 'auto', backgroundPosition: 'center' }} className='relative flex flex-col w-full h-full items-center pt-4 md:pt-10'>
        <img src='assets/img/art-music/title.png' className='w-[180px] md:w-[289px] md:h-[29px]' />
        <img src='assets/img/landing/title-line.png' className='w-[90%] lg:w-[904px] lg:h-[9px] mt-4 md:mt-6 z-[50]' />
        <div style={{ backgroundImage: 'url(assets/img/about-us/logo-line.png)', backgroundSize: 'cover', backgroundPosition: 'top' }} className="relative z-[100] w-full lg:h-[14px] flex my-8 md:my-[60px]">
          <img src='assets/img/art-music/artbook.png' className='w-[180px] md:w-[201px] lg:w-[312px] lg:h-[29px] mx-auto -mt-[8px]' />
        </div>
        <div className='w-full flex flex-col h-fit gap-y-4 md:gap-y-8'>
          <div className='flex items-center w-full h-fit bg-[#070707]'>
            <div className="relative w-full overflow-hidden py-4">
              <p className='text-[14px] md:text-[24px] text-center'>Introduction</p>
              <>
                <button onClick={() => setIndexIntro((indexIntro - 1 + intro.length) % intro.length)} className="absolute left-[12%] hidden md:block w-[20px] h-[35px] md:w-[40px] md:h-[70px] z-[100] top-[40%]"><img src='assets/img/art-music/arrow-left.svg' /></button>
                <button onClick={() => setIndexIntro((indexIntro + 1) % intro.length)} className="absolute right-[12%] hidden md:block w-[20px] h-[35px] md:w-[40px] md:h-[70px] z-[100] top-[40%]"><img src='assets/img/art-music/arrow-right.svg' /></button>
              </>
              <div className="flex h-[250px] lg:h-[300px] xl:h-[400px] 2xl:h-[450px] 3xl:h-[550px] items-center transition-transform duration-700 md:ml-[17%]" style={{ transform: `translateX(${-indexIntro * 50}%)` }}>
                {intro.map((url, idx) => (
                  <div
                    key={idx}
                    className={`flex-shrink-0 ${idx === indexIntro ? 'w-[100%] md:w-[80%]' : 'w-[50%]'} transition-all duration-300`}
                  >
                    <img
                      className='cursor-pointer'
                      src={url}
                      onClick={() => handleImageClick(0, idx)}
                    />
                  </div>
                ))}
              </div>
              <div className="flex items-center justify-center mt-4 h-6 md:h-12">
                <button onClick={() => setIndexIntro((indexIntro - 1 + intro.length) % intro.length)} className="md:hidden block w-[14px] h-[16px] md:w-[40px] md:h-[70px] z-[100] top-[40%]"><img src='assets/img/art-music/arrow-left.svg' /></button>
                {intro.map((_, idx) => (
                  <div
                    key={idx}
                    className={`rounded-full ${idx === indexIntro ? 'h-3 w-3' : 'h-2 w-2'} bg-[white] cursor-pointer mx-[4px]`}
                    onClick={() => setIndexIntro(idx)}
                  ></div>
                ))}
                <button onClick={() => setIndexIntro((indexIntro + 1) % intro.length)} className="md:hidden block w-[14px] h-[16px] md:w-[40px] md:h-[70px] z-[100] top-[40%]"><img src='assets/img/art-music/arrow-right.svg' /></button>
              </div>
            </div>
          </div>
          <div className='flex items-center w-full h-fit bg-[#070707]'>
            <div className="relative w-full overflow-hidden py-4">
              <p className='text-[14px] md:text-[24px] text-center'>Concept Art</p>
              <>
                <button onClick={() => setIndexConcept((indexConcept - 1 + concepts.length) % concepts.length)} className="absolute left-[12%] hidden md:block w-[20px] h-[35px] md:w-[40px] md:h-[70px] z-[100] top-[40%]"><img src='assets/img/art-music/arrow-left.svg' /></button>
                <button onClick={() => setIndexConcept((indexConcept + 1) % concepts.length)} className="absolute right-[12%] hidden md:block w-[20px] h-[35px] md:w-[40px] md:h-[70px] z-[100] top-[40%]"><img src='assets/img/art-music/arrow-right.svg' /></button>
              </>
              <div className="flex h-[250px] lg:h-[300px] xl:h-[400px] 2xl:h-[450px] 3xl:h-[550px] items-center transition-transform duration-700 md:ml-[17%]" style={{ transform: `translateX(${-indexConcept * 50}%)` }}>
                {concepts.map((url, idx) => (
                  <div
                    key={idx}
                    className={`flex-shrink-0 ${idx === indexConcept ? 'w-[100%] md:w-[80%]' : 'w-[50%]'} transition-all duration-300`}
                  >
                    <img
                      className='cursor-pointer'
                      src={url}
                      onClick={() => handleImageClick(1, idx)}
                    />
                  </div>
                ))}
              </div>
              <div className="flex items-center justify-center mt-4 h-6 md:h-12">
                <button onClick={() => setIndexConcept((indexConcept - 1 + concepts.length) % concepts.length)} className="md:hidden block w-[14px] h-[16px] md:w-[40px] md:h-[70px] z-[100] top-[40%]"><img src='assets/img/art-music/arrow-left.svg' /></button>
                {concepts.map((_, idx) => (
                  <div
                    key={idx}
                    className={`rounded-full ${idx === indexConcept ? 'h-3 w-3' : 'h-2 w-2'} bg-[white] cursor-pointer mx-[4px]`}
                    onClick={() => setIndexConcept(idx)}
                  ></div>
                ))}
                <button onClick={() => setIndexConcept((indexConcept + 1) % concepts.length)} className="md:hidden block w-[14px] h-[16px] md:w-[40px] md:h-[70px] z-[100] top-[40%]"><img src='assets/img/art-music/arrow-right.svg' /></button>
              </div>
            </div>
          </div>
          <div className='flex items-center w-full h-fit bg-[#070707]'>
            <div className="relative w-full overflow-hidden py-4">
              <p className='text-[14px] md:text-[24px] text-center'>3D Art</p>
              <>
                <button onClick={() => setIndexThreeD((indexThreeD - 1 + threeD.length) % threeD.length)} className="absolute left-[12%] hidden md:block w-[20px] h-[35px] md:w-[40px] md:h-[70px] z-[100] top-[40%]"><img src='assets/img/art-music/arrow-left.svg' /></button>
                <button onClick={() => setIndexThreeD((indexThreeD + 1) % threeD.length)} className="absolute right-[12%] hidden md:block w-[20px] h-[35px] md:w-[40px] md:h-[70px] z-[100] top-[40%]"><img src='assets/img/art-music/arrow-right.svg' /></button>
              </>
              <div className="flex h-[250px] lg:h-[300px] xl:h-[400px] 2xl:h-[450px] 3xl:h-[550px] items-center transition-transform duration-700 md:ml-[17%]" style={{ transform: `translateX(${-indexThreeD * 50}%)` }}>
                {threeD.map((url, idx) => (
                  <div
                    key={idx}
                    className={`flex-shrink-0 ${idx === indexThreeD ? 'w-[100%] md:w-[80%]' : 'w-[50%]'} transition-all duration-300`}
                  >
                    <img
                      className='cursor-pointer'
                      src={url}
                      onClick={() => handleImageClick(2, idx)}
                    />
                  </div>
                ))}
              </div>
              <div className="flex items-center justify-center mt-4 h-6 md:h-12">
                <button onClick={() => setIndexThreeD((indexThreeD - 1 + threeD.length) % threeD.length)} className="md:hidden block w-[14px] h-[16px] md:w-[40px] md:h-[70px] z-[100] top-[40%]"><img src='assets/img/art-music/arrow-left.svg' /></button>
                {threeD.map((_, idx) => (
                  <div
                    key={idx}
                    className={`rounded-full ${idx === indexThreeD ? 'h-3 w-3' : 'h-2 w-2'} bg-[white] cursor-pointer mx-[4px]`}
                    onClick={() => setIndexThreeD(idx)}
                  ></div>
                ))}
                <button onClick={() => setIndexThreeD((indexThreeD + 1) % threeD.length)} className="md:hidden block w-[14px] h-[16px] md:w-[40px] md:h-[70px] z-[100] top-[40%]"><img src='assets/img/art-music/arrow-right.svg' /></button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ backgroundImage: 'url(assets/img/about-us/logo-line.png)', backgroundSize: 'cover', backgroundPosition: 'top' }} className="relative z-[100] w-full lg:h-[14px] flex my-[60px]">
          <img src='assets/img/art-music/animations.png' className='w-[201px] lg:w-[390px] lg:h-[29px] mx-auto -mt-[8px]' />
        </div>
        <div className='flex items-center w-full h-fit bg-[#070707]'>
          <div className="relative w-full overflow-hidden py-4">
            <>
              <button onClick={() => setIndexAnimation((indexAnimation - 1 + animations.length) % animations.length)} className="absolute left-[12%] hidden md:block w-[20px] h-[35px] md:w-[40px] md:h-[70px] z-[100] top-[40%]"><img src='assets/img/art-music/arrow-left.svg' /></button>
              <button onClick={() => setIndexAnimation((indexAnimation + 1) % animations.length)} className="absolute right-[12%] hidden md:block w-[20px] h-[35px] md:w-[40px] md:h-[70px] z-[100] top-[40%]"><img src='assets/img/art-music/arrow-right.svg' /></button>
            </>
            <div className="flex h-[250px] lg:h-[300px] xl:h-[400px] 2xl:h-[450px] 3xl:h-[550px] items-center transition-transform duration-700 md:ml-[calc(50%-356px)]" style={{ transform: `translateX(${-indexAnimation * 514}px)` }}>
              {animations.map((url, idx) => (
                <div
                  key={idx}
                  className={`flex-shrink-0 ${idx === indexAnimation ? 'w-[100%] md:w-[712px] md:h-[400px]' : 'w-[514px] h-[300px]'} transition-all duration-300`}
                >
                  <img
                    src={`https://img.youtube.com/vi/${url.split('v=')[1]}/0.jpg`}
                    alt={`Video ${idx + 1} thumbnail`}
                    className="cursor-pointer w-full h-full"
                    onClick={() => handleVideoClick(url)}
                  />
                </div>
              ))}
            </div>
            <div className="flex items-center justify-center mt-4 h-6 md:h-12">
              <button onClick={() => setIndexAnimation((indexAnimation - 1 + animations.length) % animations.length)} className="md:hidden block w-[14px] h-[16px] md:w-[40px] md:h-[70px] z-[100] top-[40%]"><img src='assets/img/art-music/arrow-left.svg' /></button>
              {animations.map((_, idx) => (
                <div
                  key={idx}
                  className={`rounded-full ${idx === indexAnimation ? 'h-3 w-3' : 'h-2 w-2'} bg-[white] cursor-pointer mx-[4px]`}
                  onClick={() => setIndexAnimation(idx)}
                ></div>
              ))}
              <button onClick={() => setIndexAnimation((indexAnimation + 1) % animations.length)} className="md:hidden block w-[14px] h-[16px] md:w-[40px] md:h-[70px] z-[100] top-[40%]"><img src='assets/img/art-music/arrow-right.svg' /></button>
            </div>
          </div>
        </div>
        <div style={{ backgroundImage: 'url(assets/img/about-us/logo-line.png)', backgroundSize: 'cover', backgroundPosition: 'top' }} className="relative z-[100] w-full lg:h-[14px] flex my-[60px]">
          <img src='assets/img/art-music/music.png' className='w-[140px] lg:w-[196px] lg:h-[29px] mx-auto -mt-[8px]' />
        </div>
        <div className='flex items-center w-full h-fit bg-[#070707]'>
          <div className="relative w-full overflow-hidden py-4">
            <div className="flex justify-center items-center">
              <div
                className={`flex-shrink-0 w-[80%] h-[200px] md:w-[712px] md:h-[400px]`}
              >
                <img
                  src={`https://img.youtube.com/vi/${musicUrl.split('v=')[1]}/0.jpg`}
                  className="cursor-pointer w-full h-full"
                  onClick={() => handleVideoClick(musicUrl)}
                />
              </div>
            </div>
          </div>
        </div>
        {selectedImage && (
          <div className="z-[200] w-screen top-0 left-0 h-screen fixed flex justify-center items-center bg-[#00000083]" onClick={closeImageModal}>
            <div className="flex items-center relative justify-center w-[80%] h-fit" onClick={(e) => e.stopPropagation()}>
              <button onClick={() => handleImageClick(selectedImage.pack, getNextIndex(selectedImage.index, selectedImage.pack, 'prev'))} className="absolute -left-[5%] hidden md:block w-[20px] h-[35px] md:w-[40px] md:h-[70px] z-[100] top-[40%]"><img src='assets/img/art-music/arrow-left.svg' /></button>
              <button onClick={() => handleImageClick(selectedImage.pack, getNextIndex(selectedImage.index, selectedImage.pack, 'next'))} className="absolute -right-[5%] hidden md:block w-[20px] h-[35px] md:w-[40px] md:h-[70px] z-[100] top-[40%]"><img src='assets/img/art-music/arrow-right.svg' /></button>
              <TransformWrapper
                initialScale={1}
                initialPositionX={0}
                initialPositionY={0}
                wheel={{ step: 50 }}
              >
                <TransformComponent>
                  {selectedImage && (
                    <img
                      src={selectedImage.pack == 0 ? intro[selectedImage.index] :
                        selectedImage.pack == 1 ? concepts[selectedImage.index] :
                          selectedImage.pack == 2 ? threeD[selectedImage.index] :
                            undefined
                      }
                      title={concepts[selectedImage.index]}
                    />
                  )}
                </TransformComponent>
              </TransformWrapper>
            </div>
          </div>
        )}
        {selectedVideo && (
          <div className="z-[200] w-screen top-0 left-0 h-screen fixed flex justify-center items-center bg-[#0000005a]" onClick={closeModal}>
            <div className="flex items-center justify-center w-[768px] h-[500px]">
              <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${selectedVideo.split('v=')[1]}`}
                title="Selected Video"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        )}
        <div style={{ background: 'url(assets/img/landing/fourth-bottom.png)', backgroundSize: 'cover', backgroundPosition: 'top' }} className="bottom-0 z-[100] w-full h-[700px] -mt-[10px] pointer-events-none justify-end flex flex-col items-center">
          <Footer type="lg" />
        </div>
      </div>
    </div>
  );
};

export default ArtPage;