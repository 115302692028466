import Header from '../../components/header';
import Footer from '../../components/footer';
import React, { useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const videoUrls = [
  'https://www.youtube.com/watch?v=video1',
  'https://www.youtube.com/watch?v=video2',
  'https://www.youtube.com/watch?v=video3',
  'https://www.youtube.com/watch?v=video3',
  'https://www.youtube.com/watch?v=video3',
];

const VideoPage = () => {
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);

  const handleVideoClick = (url: string) => {
    setSelectedVideo(url);
  };

  const closeModal = () => {
    setSelectedVideo(null);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  return (
    <div className={`relative w-full h-fit flex flex-col bg-[#000000]`}>
      <img src='assets/img/videos/bg.png' className='w-full absolute h-[120vh]' />
      <Header />
      <div className='relative flex flex-col w-full h-full items-center pt-20'>
        <img src='assets/img/videos/title.png' className='w-[95%] md:w-[660px] md:h-[43px]' />
        <img src='assets/img/landing/title-line.png' className='w-[90%] lg:w-[904px] lg:h-[9px] md:mt-2 z-[50]' />
        <div className='flex items-center w-full md:w-full md:h-[518px] bg-[#0d131c] mt-[92px] mb-[240px]'>
          <div className='pt-10 flex items-center px-[55px] w-full justify-between md:w-full md:h-[478px] bg-[#0C0C0C]'>
            <Slider className='w-full flex h-full' {...settings}>
              {videoUrls.map((url, index) => (
                <div key={index} className="flex flex-col p-[10px] w-fit h-fit">
                  <h3 className="text-[#ED9B17] text-[24px] font-normal">Video title {index + 1}</h3>
                  <img
                    src={`https://img.youtube.com/vi/${url.split('v=')[1]}/0.jpg`}
                    alt={`Video ${index + 1} thumbnail`}
                    className="cursor-pointer w-[542px] h-[305px]"
                    onClick={() => handleVideoClick(url)}
                  />
                  <p className="video-description">Video description</p>
                </div>
              ))}
            </Slider>
          </div>
          {selectedVideo && (
            <div className="z-[200] w-screen top-0 left-0 h-screen fixed flex justify-center items-center bg-[#0000005a]" onClick={closeModal}>
              <div className="flex items-center justify-center w-[768px] h-[500px]">
                <iframe
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/${selectedVideo.split('v=')[1]}`}
                  title="Selected Video"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          )}
        </div>
        <div style={{ backgroundImage: 'url(assets/img/landing/fourth-bottom.png)', backgroundSize: 'cover', backgroundPosition: 'top' }} className="bottom-0 z-[100] w-full h-[730px] -mt-[500px] md:-mt-[32vh] pointer-events-none justify-end flex flex-col items-center">
          <Footer type="lg" />
        </div>
      </div>
    </div>
  );
};

export default VideoPage;