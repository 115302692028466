import { useEffect, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";

const GamePage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={`w-full h-full flex flex-col bg-[#00000063]`}>
      <Header />
      <div className="relative flex flex-col w-full lg:h-fit items-center">
        <div className="w-full h-fit items-center flex flex-col">
          <div
            style={{
              backgroundImage: "url(assets/img/game/bg-top.png)",
              backgroundSize: "auto",
              backgroundPosition: "center",
            }}
            className="flex w-full h-[104px] md:h-[200px] lg:h-[450px] justify-center items-center relative shadow-[black] shadow-[0_69px_89.9px_48px_rgba(0,0,0,1)]"
          >
            <img
              src="assets/img/game/bg-top-2.png"
              className="w-full h-full absolute top-0 z-[1000]"
            />
            <img
              src="assets/img/game/logo.png"
              className="w-[187px] md:w-[300px] lg:w-[850px]"
            />
          </div>
          <div
            style={{
              backgroundImage: "url(assets/img/privacy.png)",
              backgroundRepeat: "repeat",
              backgroundSize: "auto",
              backgroundPosition: "center",
            }}
            className="flex w-full h-full absolute"
          ></div>
          <div className="w-full h-fit flex flex-col z-[1000] items-center text-[white] text-[8px] leading-3 md:text-[20px] md:leading-7 2xl:text-[16px] 2xl:leading-[22px]">
            <div className="flex justify-center 2xl:flex-row flex-col gap-5 -mt-2 lg:-mt-10">
              <div className="h-full flex w-fit flex-col justify-start items-center gap-5">
                <div
                  style={{
                    backgroundImage: "url(assets/img/game/1.png)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                  className="flex flex-col 2xl:w-[877px] 2xl:h-[440px] w-[90%] h-fit py-3 md:py-12 2xl:py-5 justify-center items-start px-3 md:px-10 bg-[black]"
                >
                  <p className="text-[#FFB834] text-[18px] md:text-[24px] lg:text-[34px] font-[400]">
                    Our THUNDER
                  </p>
                  <p className="2xl:w-[281px] w-[80%]">
                    <br />
                    Have you ever dreamed of joining the battle in a world where
                    two enemy factions are fighting to gain dominance over a
                    shared territory?{" "}
                  </p>
                  <p className="2xl:w-[281px] w-[80%]">
                    <br />
                    To participate in a team-based, match-driven FPS shooter
                    where you can take a third-person RPG break to explore a
                    massive open world full of quests and stories set in a
                    universe rich with captivating lore?
                  </p>
                  <p className="w-[90%]">
                    <br />A game where you can earn money by buying, selling,
                    and renting unique game gear, all while defending your
                    faction’s cause? All of these dreams have been shared by our
                    team at IMU Studios, dreams that drove us to create THUNDER©
                    for you. us to create THUNDER© for you.
                  </p>
                </div>
                <div className="2xl:w-[877px] 2xl:h-[300px] w-[90%] h-fit py-5 md:py-20 2xl:py-5 flex justify-end bg-[#000] relative">
                  <div
                    style={{
                      backgroundImage: "url(assets/img/game/2.png)",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    className="flex flex-col 2xl:w-[805px] 2xl:h-[246px] w-full h-[100px] md:h-[246px] justify-center items-start px-3 md:px-10"
                  ></div>
                  <div className="flex flex-col absolute 2xl:w-[877px] w-[90%] h-fit 2xl:h-[286px] top-6 left-3 md:left-10">
                    <p className="text-[#FFB834] text-[18px] md:text-[24px] lg:text-[34px] font-[400]">
                      The Vision
                    </p>
                    <p className="w-[50%] md:w-[414px]">
                      <br />
                      Our work reflects the love we have for fast-paced action,
                      original game design, and true player freedom. From
                      conception to the ever-expanding universe, our
                      character-driven multiplayer gameplay is designed to offer
                      players countless.
                    </p>
                    <p className="w-[90%] hidden md:block md:w-[632px]">
                      <br />
                      ways to immerse themselves in the open world we’ve
                      created.  In this world, you are not just simple players
                      but true actors on Nebulous Prime, a planet rich in
                      mysteries and cosmic dangers. 
                    </p>
                  </div>
                </div>
              </div>
              <div className="h-full flex flex-col justify-start items-center gap-5">
                <div
                  style={{
                    backgroundImage: "url(assets/img/game/3.png)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                  className="flex flex-col w-[90%] 2xl:w-[595px] h-fit py-6 2xl:h-[364px] justify-center items-end pr-4 md:pr-10 2xl:pr-4"
                >
                  <p className="text-[#FFB834] text-[18px] md:text-[24px] lg:text-[34px] font-[400]">
                    Our Origins
                  </p>
                  <p className="w-[50%] md:w-[300px] lg:w-[40%] 2xl:w-[314px] text-end">
                    <br />
                    Our game was born in 2007 when IMU Studios developed a
                    prototype project called "Prince of THUNDER."  The 2008
                    economic crisis eventually slowed this initial step into the
                    gaming market.
                  </p>
                  <p className="w-[50%] md:w-[300px] lg:w-[40%] 2xl:w-[314px] text-end">
                    <br />
                    However, during live gaming event tours, our captivating
                    concepts inspired people and influenced new designs and
                    creations, keeping our game alive. Video game technology
                    continues to soar to new heights, and THUNDER© has become
                    better than ever.
                  </p>
                </div>
                <div className="w-[90%] 2xl:w-[595px] h-[200px] md:h-fit 2xl:h-[376px] flex justify-end bg-[#000] relative">
                  <div
                    style={{
                      backgroundImage: "url(assets/img/game/4.png)",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    className="flex flex-col w-[90%] 2xl:w-[595px] py-6 h-[200px] md:h-[352px] 2xl:h-[352px] justify-center items-start"
                  ></div>
                  <div className="flex flex-col absolute md:w-[595px] h-fit 2xl:h-[286px] top-6 left-3 md:left-10 2xl:left-4">
                    <p className="text-[#FFB834] text-[18px] md:text-[24px] lg:text-[34px] font-[400]">
                      Gear up!
                    </p>
                    <p className="w-[50%] md:w-[90%] 2xl:w-[274px]">
                      <br />
                      With 18 unique playable characters to choose from, our
                      gameplay loop revolves around a fluid combat system as
                      players dive into any of our diverse outdoor arenas to
                      control their territory. 
                    </p>
                    <p className="w-[50%] md:w-[90%] 2xl:w-[274px]">
                      <br />
                      In battle, players can use a combination of tools at their
                      disposal,  such as our extensive array of unique combat
                      devices or our elemental-powered scepters to dominate
                      their foes. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex 2xl:flex-row flex-col justify-center gap-5 mt-5">
              <div className="h-full flex flex-col justify-start items-center gap-5">
                <div className="w-[90%] 2xl:w-[575px] h-fit 2xl:h-[400px] flex justify-end bg-[#000] relative">
                  <div
                    style={{
                      backgroundImage: "url(assets/img/game/5.png)",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    className="flex flex-col w-[70%] 2xl:w-[575px] h-[220px] md:h-[580px] 2xl:h-[380px] mt-0 md:mt-4 justify-center items-start px-6"
                  ></div>
                  <div className="flex flex-col absolute w-full 2xl:w-[575px] h-fit 2xl:h-[286px] top-6 left-2 md:left-7">
                    <p className="text-[#FFB834] text-[18px] md:text-[24px] lg:text-[34px] font-[400]">
                      The multi-experience
                    </p>
                    <p className="w-[90%] md:w-[495px] mt-3">
                      <br />
                      1. Throne Wars = Fast-paced intense outdoor arena
                      gameplay.
                      <br />
                      <br />
                      2. Bonfire Stories = Relaxing and immersive in-game
                      experiences
                      <br />
                      <br />
                      3. Quests = Become engaged in the exciting events
                      occurring all over Nebulous Prime, a planet teeming with
                      exotic flora, fauna, and a vast cast of interesting
                      characters.
                      <br />
                      <br />
                      4. Crafting = Collect raw ingredients and use them to
                      craft valuable items needed by other players.
                      <br />
                      <br />
                      5. Marketplace = Sell and rent your game gear for a
                      growing real-world profit.
                    </p>
                  </div>
                </div>
              </div>
              <div className="h-full flex flex-col justify-start items-center gap-5">
                <div
                  style={{
                    backgroundImage: "url(assets/img/game/6.png)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                  className="flex flex-col w-[90%] 2xl:w-[902px] h-fit 2xl:h-[150px] py-8 2xl:py-0 justify-center items-end pl-4"
                >
                  <p className="w-full">
                    One of our goals is to connect you to valuable game gear
                    that increases in rarity and value over time. 
                  </p>
                  <p className="w-full">
                    <br />
                    This increase in worth not only occurs in active gameplay
                    but also as other players level up your gear while you are
                    offline.  With you in mind, no other game offers this level
                    of depth and variety to benefit players.
                  </p>
                </div>
                <div
                  style={{
                    backgroundImage: "url(assets/img/game/7.png)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                  className="flex flex-col w-[90%] 2xl:w-[902px] h-fit 2xl:h-[230px] py-8 2xl:py-0 justify-center items-start pl-4 bg-[black]"
                >
                  <p className="text-[#FFB834] text-[18px] md:text-[24px] lg:text-[34px] font-[400]">
                    Upgrade and Explore
                  </p>
                  <p className="w-full">
                    <br />
                    Players can fly freely with their own unique sets of wings
                    for each character, which can be upgraded as higher levels
                    are reached. 
                  </p>
                  <p className="w-full">
                    <br />
                    They can dive into the depths of the oceans, cross vast
                    wilderness, and even visit unexplored islands.  We’ve spent
                    years developing our explosive gameplay model to provide a
                    fun, fair, and fulfilling experience for all players,
                    regardless of skill level and playstyle.
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundImage: "url(assets/img/game/8.png)",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              className="flex flex-col 2xl:w-[1497px] 2xl:h-[290px] w-[90%] h-fit py-8 justify-center items-center px-4 md:px-10 bg-[black] mt-5"
            >
              <p className="text-[#FFB834] text-[18px] md:text-[24px] lg:text-[34px] font-[400]">
                Better than Free to Play
              </p>
              <p className="w-full text-center">
                <br />
                <br />
                Players can fly freely with their own unique sets of wings for
                each character, which can be upgraded as higher levels are
                reached.  They can dive into the depths of the oceans, cross
                vast wilderness, and even visit unexplored islands.  We’ve spent
                years developing our explosive gameplay model to provide a fun,
                fair, and fulfilling experience for all players, regardless of
                skill level and playstyle.
              </p>
              <p className="w-full text-center">
                <br />
                By including game gear that increases in value for players in
                real life, THUNDER© stands out from other games by pioneering
                our exclusive "Play, for Profit" gameplay model.  Here’s how it
                works: owners’ digital gear resides in the common marketplace,
                allowing other players to rent, use, and level up the game gear
                that you own while you’re not using it.  This enables players to
                earn money by increasing the value of your owned items as they
                level up.  This innovative design balances fast-paced
                competitiveness on the battlefield with growing collaboration
                outside of it.  A whole new world of possibilities, specifically
                designed to make players the heartbeat and soul, to become the
                most valuable assets of our community.
              </p>
            </div>
            <p className="text-[#FFB834] mb-[15vh] text-[12px] md:text-[24px] text-center mt-6 w-[90%]">
              <br />
              One company, one dream, one goal: making games all in, for you!
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundImage: "url(assets/img/landing/fourth-bottom.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="bottom-0 lg:z-[500] -mt-[315px] lg:-mt-[40vh] z-[600] w-full h-[750px] lg:h-[820px] pointer-events-none justify-end flex flex-col items-center"
      >
        <Footer type="lg" />
      </div>
    </div>
  );
};

export default GamePage;
