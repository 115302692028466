import { useInView } from 'react-intersection-observer';
import Discover from '../../components/landing/discover';

const ThirdSection = () => {
    const { ref, inView } = useInView({ threshold: 0.1 });

    return (
        <div className='flex w-full'>
            <div className={`absolute hidden top-[calc(1300px+30vw)] left-0 w-full h-[1000px] lg:flex flex-col items-center z-[200] bg-[#00000071] transition-opacity duration-1000`} style={{ opacity: inView ? 0 : 1, }} />
            <div style={{
                backgroundImage: 'url(assets/img/landing/third-bg.png)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                transition: 'opacity 2s ease-in-out'
            }} className='relative hidden lg:flex flex-col items-center justify-between w-full h-[974px]'>
                <div ref={ref} className='absolute top-[100px] h-[970px] w-full'></div>
                <div style={{ backgroundImage: 'url(assets/img/landing/section-line.png)', backgroundSize: 'cover', backgroundPosition: 'center' }} className="z-[100] w-full h-[26px] flex flex-col items-center">
                    <img src='assets/img/landing/incredble.png' className='w-[622px] h-[42px] mt-4 z-[50]' />
                    <img src='assets/img/landing/title-line.png' className='w-[604px] h-[10px] mt-3 z-[50]' />
                </div>
                <Discover />
            </div>
            <div className='lg:hidden relative flex flex-col items-center justify-between w-full h-fit'>
                <div style={{ backgroundImage: 'url(assets/img/landing/section-line.png)', backgroundSize: 'cover', backgroundPosition: 'center' }} className="z-[100] w-full h-[26px] flex flex-col items-center">
                    <img src='assets/img/landing/incredble.png' className='w-[85%] md:w-[622px] md:h-[42px] mt-4 z-[50]' />
                    <img src='assets/img/landing/title-line.png' className='w-[90%] md:w-[604px] md:h-[10px] mt-1 z-[50]' />
                </div>
                <Discover />
                <img src='assets/img/landing/section-line-mb.png' className='w-full h-[14px] mt-4 z-[50]' />
                <img src='assets/img/landing/third-bg.png' className='w-full -mt-3 z-[49]' />
            </div>
        </div>
    )
}
export default ThirdSection;