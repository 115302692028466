import Header from '../../components/header';
import Footer from '../../components/footer';
import TestimonialItem from '../../components/update/testimonials-item';

const userComments = [
  {
    "name": "redrix745",
    "linkedin": "",
    "role": "",
    "summary": "“wow looks spot on and really awesome”"
  },
  {
    "name": "Radiotic",
    "linkedin": "",
    "role": "",
    "summary": "“Man that water and vegetation looks amazing”"
  },
  {
    "name": "helpfullkitty",
    "linkedin": "",
    "role": "",
    "summary": "“you will be able to get real money out of the game?”"
  },
  {
    "name": "helpfullkitty",
    "linkedin": "",
    "role": "",
    "summary": "“i could be a tester or help with anything right way, if you need somebody to help.”"
  },
  {
    "name": "Chandra",
    "linkedin": "",
    "role": "",
    "summary": "“very good music, the game is so good” “this is the best game I have ever seen” “So, we won't die?” “Nice to see so much progress!”"
  },
  {
    "name": "ZeroMarkov",
    "linkedin": "",
    "role": "",
    "summary": "“I've heard of the progress… But seeing it is WOW! I can see by the light in your eyes that you're so happy about it, it just makes me smile too, for real. Your game design blows me away... you are sitting on a giant hit... no death idea is the hook because u never leave the game and the zero reload time perpetuates an infinite game loop... gaming crack :)”"
  },
  {
    "name": "Robin",
    "linkedin": "",
    "role": "",
    "summary": "“the vision sounds decidedly juicy, innovative, and intriguing.”"
  },
  {
    "name": "Brooke Catheryn",
    "linkedin": "",
    "role": "",
    "summary": "“It sounds fascinating and fun.”"
  },
  {
    "name": "hhyyt",
    "linkedin": "",
    "role": "",
    "summary": "“just wanna say i love what i see”"
  },
  {
    "name": "Henrique",
    "linkedin": "",
    "role": "",
    "summary": "“YOU are really wonderful man! You have a huge and very kind heart.”"
  },
  {
    "name": "Twitchyswordhand",
    "linkedin": "",
    "role": "",
    "summary": "“I like the easter egg secrets.”"
  },
  {
    "name": "zwillbigboss",
    "linkedin": "",
    "role": "",
    "summary": "“whoah I like that (hidden rooms)”"
  },
  {
    "name": "TheAnonyBOSS",
    "linkedin": "",
    "role": "",
    "summary": "“lots of progress since i was last here”"
  },
  {
    "name": "TheDoctor01952",
    "linkedin": "",
    "role": "",
    "summary": "“Like the look of the game, looks good.”"
  },
  {
    "name": "Cody",
    "linkedin": "",
    "role": "",
    "summary": "“Love the look and jump mechanics”"
  },
  {
    "name": "HM300",
    "linkedin": "",
    "role": "",
    "summary": "“my friends are going nuts! Hahaha They wanna play it so much!”"
  },
  {
    "name": "DrizzGamez",
    "linkedin": "",
    "role": "",
    "summary": "“dang! super fast-paced, that's awesome!”"
  },
  {
    "name": "Shinekittenlove",
    "linkedin": "",
    "role": "",
    "summary": "“This map is beautiful…”"
  },
  {
    "name": "Shinekittenlove",
    "linkedin": "",
    "role": "",
    "summary": "“NO FALL DAMAGE!”"
  },
  {
    "name": "blue_xphos",
    "linkedin": "",
    "role": "",
    "summary": "“YESSSS no self-damage”"
  },
  {
    "name": "puresaltgoodness",
    "linkedin": "",
    "role": "",
    "summary": "“rocket jump?” “that is really smart” “now i know what you mean about how its new”"
  },
  {
    "name": "HM300",
    "linkedin": "",
    "role": "",
    "summary": "“Looking fantaaaastic”"
  },
  {
    "name": "vitm300",
    "linkedin": "",
    "role": "",
    "summary": "“omg amazing”"
  },
  {
    "name": "Keuken_hulpmiddel",
    "linkedin": "",
    "role": "",
    "summary": "“Damn that looks good”"
  },
  {
    "name": "ZeroMarkov",
    "linkedin": "",
    "role": "",
    "summary": "“This song... is AMAZING”"
  },
  {
    "name": "Kailler_a",
    "linkedin": "",
    "role": "",
    "summary": "“How i stop throwing my money to buy this game?”"
  },
  {
    "name": "cakeblock941",
    "linkedin": "",
    "role": "",
    "summary": "“the game is free, kailler lol”"
  },
  {
    "name": "Hhowever",
    "linkedin": "",
    "role": "",
    "summary": "“amazing! Love it”"
  },
  {
    "name": "Legendaryghost27",
    "linkedin": "",
    "role": "",
    "summary": "“i am so looking forward to this game”"
  },
  {
    "name": "Amy",
    "linkedin": "",
    "role": "",
    "summary": "“This (music) is amazing, and I love it so much,”"
  },
  {
    "name": "Jaxilon",
    "linkedin": "",
    "role": "",
    "summary": "“it's fun to see a grown man get all excited about it :)”"
  },
  {
    "name": "Terry",
    "linkedin": "",
    "role": "",
    "summary": "“I want to play THAT game!”"
  },
  {
    "name": "Iman",
    "linkedin": "",
    "role": "",
    "summary": "“This is just awesome! This music is awesome. I listen to this type of music all the time. Can I have a copy of it? 28-year-old University Student”"
  },
  {
    "name": "Daniel",
    "linkedin": "",
    "role": "",
    "summary": "“This is total fun. Now this is what fun is called!”"
  },
  {
    "name": "Dana (game tester)",
    "linkedin": "",
    "role": "",
    "summary": "“Your game plays as smooth as butter”"
  },
  {
    "name": "da1youngmoney",
    "linkedin": "",
    "role": "",
    "summary": "“Wow! I really love the look of the icons. And I really love the devices. They look very unique and pretty. Also, the water looks super sleek. I can't wait to get into the game!”"
  },
  {
    "name": "Zeronical",
    "linkedin": "",
    "role": "",
    "summary": "“The functionality of each device is so on point with the looks”"
  },
  {
    "name": "Louis",
    "linkedin": "",
    "role": "",
    "summary": "“I believe that IMU’s business model will define and become the leading industry format in the years to come.”"
  },
  {
    "name": "Alienics",
    "linkedin": "",
    "role": "",
    "summary": "“I CAN’T WAIT TO ACTUALLY PLAY IT”"
  },
  {
    "name": "Cakeblock941",
    "linkedin": "",
    "role": "",
    "summary": "“WE CANT WAIT”"
  }
]

const teamMembers = [
  {
    "name": "Alejandro Cacho Tena",
    "linkedin": "https://www.linkedin.com/in/alejandro-cacho-tena-8728b765/",
    "role": "Lead Environment Artist",
    "summary": "Everyone I know at IMU Studios is completely focused on developing the best possible game and getting something new and exciting for people to play out there. The founder, Robert, is an amazing and very professional guy with the right mindset on how to create a game that is beautiful to look at and extremely fun to play and he has collected a bunch of crazy talented and creative people in order to do so, I have nothing but nice things to say about him and the team developing the game."
  },
  {
    "name": "Derick Mungahu",
    "linkedin": "https://www.linkedin.com/in/derick-mungahu-4944a1201",
    "role": "Human Resources",
    "summary": "IMU Studios is an innovative and forward-thinking company dedicated to pushing the boundaries of interactive entertainment and technology. Their commitment to creating immersive gaming experiences, combined with a strong vision for creative storytelling, sets them apart in the industry. The team at IMU Studios is not only talented but also highly collaborative and adaptive, making them exceptional partners to work with on complex projects. Their leadership demonstrates a clear strategy and an unwavering passion for quality. I highly recommend IMU Studios for anyone looking to invest in or partner with a company that is shaping the future of entertainment."
  },
  {
    "name": "Jacob Hernandez",
    "linkedin": "https://www.linkedin.com/in/jacob-hernandez-0a55ab196/",
    "role": "3D Modeler",
    "summary": "I've been enjoying my time here at IMU Studios. This team is filled with a lot of talented artists, who are putting their heart and passion for games into Thunder. With Unreal 5's improved technology, its visuals are striking, with better dynamic lighting, photorealistic textures, and 3D scanned characters; all built on top of our original, fantasy concepts! In Thunder, players will explore vast, open landscapes, where they will form alliances, or fight against otherworldly monsters. Our studio is passionate about bringing Thunder to life, and we firmly believe that with the right support, this project has the potential to capture the imagination of players worldwide. The game's unique premise, combined with the high standards of quality we're upholding in both gameplay and aesthetics, ensures that it will stand out in the competitive multiplayer genre. I fully recommend funding Thunder, as I'm confident it will deliver a great gaming experience that fans of the genre will love."
  },
  {
    "name": "Zareena K.",
    "linkedin": "https://www.linkedin.com/in/zareena-k-59166720/",
    "role": "Human Resources",
    "summary": "IMU Studios is a highly innovative game development company that excels at creating immersive, visually stunning gaming experiences. Their commitment to cutting-edge technology and exceptional storytelling sets them apart in the industry. Under the visionary leadership of their CCO, Robert Strutin who has a deep passion for pushing creative boundaries, IMU Studios consistently delivers high-quality, engaging games that captivate players. The founder's dedication to fostering a dynamic and forward-thinking team has been instrumental in the company’s growing success. IMU Studios is poised for continued growth and success, making them a strong candidate for the F6S startup program."
  },
  {
    "name": "Kaustubh Deshmukh",
    "linkedin": "https://linkedin.com/",
    "role": "Advisor",
    "summary": "I have been associated with Robert and the IMU Studios team for over a year. I am deeply impressed by the team's talent, enthusiasm, and commitment. Led by Robert's grand vision for the Thunder platform, the team is definitely going to make an amazing game."
  },
  {
    "name": "Loris Bussolotti",
    "linkedin": "https://www.linkedin.com/in/loris-bussolotti-08788a198",
    "role": "Art Director",
    "summary": "I have been working as the Art Director & Concept Art Lead at iPlayMore/IMU Studios for more than one year and a half, after studying at the academy Digital Bros Game Academy in Italy. I can say, without a doubt, that Robert has proven to be a great leader who actually listens and works with his team, discarding completely the 'boss' behaviors, embracing a true 'leader' role which is fundamental for a project to succeed. After working here for more than one year I still see a STRONG determination from our leader and the whole team, being in the pre-funding stage doesn't affect our productivity and efficiency, but it gives us fuel to impress, amaze, and leave our future investors speechless!"
  },
  {
    "name": "Alana Fairman",
    "linkedin": "https://www.linkedin.com/in/alana-fairman-b6944a133/",
    "role": "Concept Artist",
    "summary": "Working at IMU Studios has been an extremely fulfilling and enjoyable experience. Both the 2D and 3D teams are filled with exceptionally talented artists, and the communication between each has pushed us to create something unique, visually stunning, and fantastical. I'm floored by the progress and the quality of work everyone has produced despite being unfunded, and I can only imagine how that will all skyrocket with funding. Robert has created an environment where the artists and team leads are all comfortable sharing their ideas and collaborating, and his leadership inspires us to produce work of the highest caliber."
  },
  {
    "name": "Mansoor Bilal",
    "linkedin": "https://www.linkedin.com/in/mansoor-bilal/",
    "role": "3D Character Artist",
    "summary": "I'm really impressed by IMU Studios, which is creating a really awesome game. This game isn't just fun; it challenges your mind and tells a captivating story. You can tell that the IMU team pours their passion into their work, which shines through in their game. They are a standout company in the gaming industry, and I highly recommend IMU Studios to anyone who appreciates innovative and immersive gaming experiences."
  },
  {
    "name": "Artem Zakrevskii",
    "linkedin": "https://www.linkedin.com/in/artem-zakrevskii-2dartist/",
    "role": "Concept Artist",
    "summary": "IMU Studios stands at the forefront of innovation and creativity, with a project that is both ambitious and groundbreaking. Under Robert's leadership, the team has made remarkable strides in transforming their vision into reality, laying a strong foundation for future success. The passion and dedication that drive this project are evident in the incredible amount of work already accomplished."
  },
  {
    "name": "Sergio Sánchez",
    "linkedin": "https://www.linkedin.com/in/sergio-s%C3%A1nchez-a1635b260",
    "role": "Lead 3D Character Artist",
    "summary": "This company is huge, and composed by people from different countries, that are putting their efforts together from bringing this project to life. I would like to put the focus on Robert, our director, in this path. He is an honest person who cares about his team and does his best to maintain a healthy and pleasant environment despite the distance and the artistic and technical frustrations that sometimes come with developing video games."
  },
  {
    "name": "Daniel Mendiuk",
    "linkedin": "https://www.linkedin.com/in/mendiukd/",
    "role": "Senior Lead Environmental Artist",
    "summary": "IMU Studios is pioneering new unforgettable experiences players yearn for. The game design facilitates immersive and emergent gameplay, which is highly marketable for viral content. This will generate boundless sales. The high earning potential, along with the extensive expertise of the team, guarantees the project's success. I have witnessed the exponential growth of the team's creativity, leadership, design, and technical prowess."
  },
  {
    "name": "Scott Simpson",
    "linkedin": "https://www.linkedin.com/in/scott-simpson-788b281b9", 
    "role": "Lead Level Designer",
    "summary": "Amazing company to work with. I began my work back in 2018 with no background or schooling in game development. But it turned out that with my gaming experience and unique perspective, I have been able to work my way into the development of the project. Currently the Lead Level Designer, I am able to create the world you are seeing with the input and suggestions of the team, and freedom to create the best experience for the players as we continue to receive amazing models and character designs that are being added into the world."
  }
]

const Testimonials = () => {

  return (
    <div className={`w-full h-fit flex flex-col bg-[#000000]`}>
      <img src='assets/img/update/bg-1.png' className='w-full opacity-80 absolute h-[120vh]' />
      <img src='assets/img/update/bg-2.png' className='w-full absolute h-[120vh]' />
      <Header />
      <div className='relative flex flex-col w-full h-full items-center'>
        <div className="bg-[#0d131c] bottom-0 z-[100] w-[90%] xl:w-[1160px] h-[120vh] -mt-16 pt-[100px] lg:pt-[12vh] flex flex-col items-center">
          <div className='flex flex-col items-center w-full h-[84vh] md:h-[90vh]'>
            <img src='assets/img/testimonials/title.png' className='w-[210px] md:w-[459px] md:h-[29px] mb-3 md:mb-7' />
            <img src='assets/img/landing/title-line.png' className='w-[90%] lg:h-[12px] z-[50] mb-3 md:mb-7' />
            <div className='flex flex-col items-center w-full h-[80vh] overflow-y-auto pt-4 pb-20 md:pb-10'>
              <img src='assets/img/testimonials/team.png' className='w-[74px] md:w-[180px] mb-3 md:mb-8' />
              {teamMembers.map((member, index) => (
                <TestimonialItem key={index} member={member} />
              ))}
              <img src='assets/img/testimonials/supporters.png' className='w-[194px] md:w-[431px] md:mt-8 mb-3 md:mb-8' />
              {userComments.map((member, index) => (
                <TestimonialItem key={index} member={member} />
              ))}
            </div>
          </div>
        </div>
        <div style={{ backgroundImage: 'url(assets/img/landing/fourth-bottom.png)', backgroundSize: 'cover', backgroundPosition: 'top' }} className="bottom-0 z-[200] w-full h-[740px] -mt-[500px] md:-mt-[40vh] pointer-events-none justify-end flex flex-col items-center">
          <Footer type="lg" />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;